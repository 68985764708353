import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authDisabled: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      authDisabled: true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    meta: {
      authDisabled: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'),
    meta: {
      authDisabled: true
    }
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/PasswordReset.vue'),
    meta: {
      authDisabled: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import(/* webpackChunkName: "friends" */ '../views/Friends.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/list/add',
    name: 'ListAdd',
    component: () => import(/* webpackChunkName: "list-add" */ '../views/ListAdd.vue'),
    meta: {
      hasBackButton: true
    }
  },
  {
    path: '/list/settings/:id',
    name: 'ListSettings',
    component: () => import(/* webpackChunkName: "list-settings" */ '../views/ListSettings.vue'),
    meta: {
      hasBackButton: true
    }
  },
  {
    path: '/:username/:url',
    name: 'ListView',
    component: () => import(/* webpackChunkName: "list-view" */ '../views/ListView.vue'),
    meta: {
      authDisabled: true,
      allowBodyClasses: true,
      hasBackButton: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const authDisabled = to.matched.some(record => record.meta.authDisabled)
  const hasBodyClasses = to.matched.some(record => record.meta.allowBodyClasses)
  const hasBackButton = to.matched.some(record => record.meta.hasBackButton)
  if (hasBackButton) {
    store.historyStack.push(from.path)
  } else {
    store.historyStack = []
  }
  if (!hasBodyClasses) {
    // Remove body classes that were added on list view pages.
    document.body.classList.remove('default', 'holiday', 'jingle', 'santa', 'mardigras', 'spooky', 'dark', 'fall', 'usa', 'sunset')
  }
  if (!authDisabled && !store.isLoggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
