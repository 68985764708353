<template>
  <div id="home-page">
    <div class="grid">
      <div class="copy-box">
        <h2>Lists for you, your friends, or the world</h2>
        <p>Perfect for bucket lists, movies to watch, personal or shared todo lists. Build a public or private list and start checking things off. Or build a group list to collaborate with friends.</p>
        <p><router-link class="btn" to="/signup">Signup Now</router-link></p>
      </div>
      <div class="img-wrap">
        <img class="sample-img" src="../assets/sample-screens.jpg" alt="Sample Screens" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped lang="sass">
#home-page
  @media only screen and (min-width: 800px)
    padding: 25px 0
.grid
  background-color: #fff
  border-radius: 10px
  padding: 30px 15px
  @media only screen and (min-width: 800px)
    display: flex
    align-items: center
    padding: 50px
.copy-box
  @media only screen and (min-width: 800px)
    flex: 2
    text-align: left
  p
    @media only screen and (min-width: 800px)
      font-size: 21px
      padding-right: 50px
      margin-bottom: 30px
  .btn
    margin: 0
    @media only screen and (min-width: 800px)
      font-size: 24px
.img-wrap
  @media only screen and (min-width: 800px)
    flex: 3
h2
  font-size: 42px
  margin-bottom: 15px
  @media only screen and (min-width: 800px)
    font-size: 56px
    margin-bottom: 25px
</style>
