<template>
  <div class="user-toolbar">
    <ul>
      <li><router-link to="/dashboard" class="icon icon-dash">Home</router-link></li>
      <li><router-link to="/search" class="icon icon-search">Explore</router-link></li>
      <!-- <li><router-link to="/friends">Friends</router-link></li> -->
      <li><router-link to="/account" class="profile-image"><img :src="profileImage" alt="Profile Image"></router-link></li>
    </ul>
  </div>
</template>

<script>
import store from '../store.js'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'UserToolbar',
  data () {
    return {
      profileImage: API_URL + store.user.image
    }
  }
}
</script>

<style scoped lang="sass">
.user-toolbar
  background-color: #fff
  position: fixed
  bottom: 0
  left: 0
  z-index: 50
  width: 100%
  border-top: 1px solid #eee
  padding: 12px 15px
ul
  list-style-type: none
  padding: 0
  line-height: 0
li
  display: inline-block
.icon
  display: block
  margin: 0 30px
  height: 0
  padding-top: 24px
  width: 24px
  background-size: contain
  background-repeat: no-repeat
  overflow: hidden
  text-indent: -9999px
.icon-dash
  background-image: url('../assets/icon-home.svg')
.icon-search
  background-image: url('../assets/icon-search.svg')
.profile-image
  display: block
  margin: 0 30px
  width: 24px
  height: 24px
  img
    border-radius: 50%
</style>
