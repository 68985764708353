<template>
  <PageToolbar/>
  <div class="notification" v-if="updateExists">
    <p>There is a new version of the app available.</p>
    <div class="btn-secondary" v-on:click="updateApp">Refresh</div>
  </div>
  <div class="page">
    <div class="container">
      <router-view/>
    </div>
  </div>
  <UserToolbar v-if="isLoggedIn" />
</template>

<script>
import PageToolbar from '@/components/PageToolbar.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import store from '@/store.js'

const user = JSON.parse(localStorage.getItem('user'))

export default {
  name: 'App',
  components: {
    PageToolbar,
    UserToolbar
  },
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  computed: {
    refreshNeeded () {
      return false
    },
    isLoggedIn () {
      return store.isLoggedIn
    }
  },
  created () {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        }
      )
    }

    if (user !== null && user.token) {
      store.isLoggedIn = true
      store.user = {
        id: user.id,
        username: user.username,
        email: user.email,
        image: user.image
      }
    }
  },
  methods: {
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    },
    updateApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
      // window.location.reload(true)
    }
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap')
html
  box-sizing: border-box
*, *:before, *:after
  box-sizing: inherit
html,
body
  min-height: 100%
body
  font-family: Avenir, Helvetica, Arial, sans-serif
  font-size: 16px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  margin: 0
  padding: 48px 0
  background-color: #4158D0
  background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)
  color: #1a233f
img
  max-width: 100%
  height: auto
h1, h2, h3, h4, h5, h6
  font-family: Exo, Helvetica, Arial, sans-serif
  line-height: 1.1
  margin: 0
h2
  font-size: 36px
  margin-bottom: 25px
  @media only screen and (min-width: 600px)
    margin-bottom: 35px
  &.page-title
    font-size: 16px
    text-transform: uppercase
    letter-spacing: 3px
    color: rgba(255,255,255,0.8)
    font-family: Avenir, Helvetica, Arial, sans-serif
h3
  font-size: 20px
  margin-bottom: 10px
ul, ol
  margin: 0
a
  text-decoration: none
  color: #090f20
  &:focus
    outline: 0
.srt
  border: 0
  clip: rect(1px, 1px, 1px, 1px)
  clip-path: inset(50%)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px
  word-wrap: normal !important
.notification
  padding: 15px
  background-color: #090f20
  color: #fff
.page
  padding: 25px 10px
  @media only screen and (min-width: 600px)
    padding: 25px
.container
  max-width: 1100px
  margin: 0 auto
.container-sm
  max-width: 600px
  margin: 0 auto
.btn,
.btn-action,
.btn-secondary
  display: inline-block
  padding: 10px 25px
  margin: 0 6px 15px
  border: 0
  border-radius: 10px
  text-decoration: none
  background-color: #ff2768
  color: #fff
  text-align: center
  cursor: pointer
  font-family: Exo, Helvetica, Arial, sans-serif
  font-size: 20px
  letter-spacing: 1px
  &[disabled]
    opacity: 0.5
    cursor: not-allowed
.btn-action
  padding: 5px 10px
  background-color: #fff
  border: 1px solid #ff2768
  color: #ff2768
  font-family: Avenir, Helvetica, Arial, sans-serif
  font-size: 16px
.btn-secondary
  background-color: #35CDEB
form
  max-width: 600px
  margin: 0 auto
  label
    display: inline-block
    padding-bottom: 3px
    font-size: 15px
.form-group
  background-color: #fff
  @media only screen and (min-width: 800px)
    padding: 15px
input[type="text"],
input[type="phone"],
input[type="email"],
input[type="password"],
select
  display: block
  width: 100%
  background-color: #fff
  border: 1px solid #eeddee
  border-radius: 5px
  padding: 15px
  font-size: 18px
  &.error
    border-color: #c30
  &:focus
    border-color: #aa55aa
    outline: 0
input[disabled]
  opacity: 0.5
  cursor: not-allowed
select
  appearance: none
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23aa55aa' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat
  background-position: calc(100% - 15px) center
  padding-right: 40px
.form-input
  padding: 15px
  text-align: left
.form-actions
  text-align: center
.form-error
  color: #c30
.form-group,
.list-group
  border-radius: 10px
  margin-bottom: 5px
  max-width: 600px
  margin-left: auto
  margin-right: auto
.list-group
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin-bottom: 30px
.list,
.list-link,
.list-link-more
  text-align: left
  width: 100%
.list,
.list-link > a,
.list-link-more > a
  display: block
  padding: 15px
  border-bottom: 1px solid #F9F3F9
  background-color: #fff
  &:focus
    background-color: #000
    color: #fff
.list:first-child,
.list-link:first-child > a,
.list-link-more:first-child > a
  border-top-left-radius: 10px
  border-top-right-radius: 10px
.list:last-child,
.list-link:last-child > a,
.list-link-more:last-child > a
  border-bottom: 0
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
.list-link
  & > a
    position: relative
    &:after
      content: ""
      display: block
      position: absolute
      top: 50%
      right: 15px
      width: 9px
      height: 16px
      margin-top: -8px
      background: url('assets/icon-chevron.svg') no-repeat
      background-size: contain
.list-link-more
  overflow: hidden
.list-table
  margin: 25px auto
  padding: 0
  list-style: none
  max-width: 500px
  text-align: left
  background-color: #fff
  border-radius: 5px
  li
    display: flex
    padding: 8px 15px
    strong
      flex: 1
      padding-right: 8px
    span
      flex: 3
.page-loader
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: rgba(0,0,0,0.75)
  z-index: 25
  display: flex
  align-items: center
  justify-content: center
.user-selected
  display: inline-block
  padding: 5px 10px
  background-color: #f6eef6
  border-radius: 5px
  margin: 0 5px 5px 0
  cursor: pointer
.users-filter
  border: 1px solid #eeddee
  input[type="text"]
    border: 0
    padding: 10px
.users-selector
  border: 1px solid #eeddee
  border-radius: 5px
  .scroll
    height: 235px
    overflow: scroll
.user-option
  display: flex
  align-items: center
  padding: 5px 10px
  border-bottom: 1px solid #eeddee
  cursor: pointer
  &:last-child
    border: 0
  &.active
    background-color: #f6eef6
  img
    width: 25px
    border-radius: 50%
    vertical-align: middle
  .username
    padding-left: 5px
.message
  padding: 10px 15px
  margin: 0 0 25px
  border-radius: 10px
  background-color: #fff
  font-weight: bold
.message-error
  color: #ff2768
</style>
