<template>
  <div class="page-toolbar">
    <span class="icon icon-back" v-if="showBack" v-on:click="goBack"><span class="srt">Back</span></span>
    <h1><router-link to="/"><img src="../assets/logo3.png" alt="socialists" /></router-link></h1>
    <ul>
      <li v-if="!isLoggedIn"><router-link to="/login">Login</router-link></li>
      <li v-if="!isLoggedIn"><router-link to="/signup">Signup</router-link></li>
    </ul>
    <ul class="actions" v-if="isLoggedIn">
      <li><router-link to="/list/add" class="icon-add">New List</router-link></li>
    </ul>
  </div>
</template>

<script>
import store from '../store.js'

export default {
  name: 'PageToolbar',
  computed: {
    isLoggedIn () {
      return store.isLoggedIn
    },
    showBack () {
      return store.isLoggedIn && store.historyStack.length > 0
    }
  },
  methods: {
    goBack () {
      store.historyStack.pop()
      // This does not save page position.
      // this.$router.push({ path: store.historyStack.pop() })
      // Currently just using this because it saves page position.
      this.$router.back(-1)
    }
  }
}
</script>

<style scoped lang="sass">
.page-toolbar
  background-color: #090f20
  color: #fff
  display: flex
  padding: 5px 20px
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 48px
  z-index: 50
  @media only screen and (min-width: 600px)
    height: 55px
.icon
  width: 50px
  height: 100%
  display: inline-block
  background-repeat: no-repeat
  background-position: center left
  background-size: 30px
  cursor: pointer
.icon-back
  background-image: url('../assets/icon-back.svg')
  margin-right: 10px
h1
  max-width: 128px
  @media only screen and (min-width: 600px)
    max-width: 150px
ul
  list-style-type: none
  padding: 0
  margin-left: auto
li
  display: inline-block
  margin: 0 10px
a
  color: #fff
.actions
  line-height: 0
  li
    margin: 0 0 0 20px
  a
    display: block
    height: 0
    padding-top: 24px
    width: 24px
    background-size: contain
    background-repeat: no-repeat
    overflow: hidden
    text-indent: -9999px
.icon-add
  background-image: url('../assets/icon-add.svg')
</style>
